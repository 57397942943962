import React, {useState, useEffect} from "react"
import MediumBlogItem from "../blog-item/blog-item-medium.component"


import styles from "./blog-preview.module.scss"

const MediumBlogPreview = ({ limit }) => {
  const [mediumPosts, setMediumPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // get all the posts from medium
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@vietjs`
    )
      .then((res) => res.json())
      .then((res) => {
        if(res.items){
          setMediumPosts(res.items);
        }
        setIsLoading(false)
      })
      .catch((err) => setMediumPosts([]))
  }, [])

  return !isLoading ? (
    <div className={styles["blog-preview-medium"]}>
      {mediumPosts.slice(0, limit ? limit : mediumPosts.length).map((post) => {
        const date = post.pubDate;
        const tags = post.categories;
        const author = post.author;
        const title = post.title;
        const excerpt = post.description;
        const imgFluid = post.thumbnail;
        const slug = post.link;
        return (
          <MediumBlogItem
            key={post.guid}
            author={author}
            tags={tags}
            date={date}
            title={title}
            excerpt={excerpt}
            imgFluid={imgFluid}
            slug={slug}
          />
        )
      })}
    </div> 
  ) : <h1>Loading...</h1>
}

export default MediumBlogPreview
