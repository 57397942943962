import React from "react"
import styles from "./blog-item-medium.module.scss"
import { Link } from "gatsby"
const MediumBlogItem = ({ title, date, excerpt, imgFluid, slug, author, tags }) => {
  return (
    <div className={styles["blog-item"]}>
      <div className={styles["blog-info"]}>
        <div className={styles["blog-heading"]}>
          <a target="_blank" href={slug} className={styles.title}>
            <span>{title}</span>
          </a>{" "}
          <span className={styles.author}>{author}</span>
          <span className={styles.date}> - {new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
        </div>
        <div dangerouslySetInnerHTML={{__html: excerpt}} className={styles["blog-summary"]}></div>
        {tags ? (
          <div className={styles.tags}>
            Tags:{" "}
            {tags.map((tag, index) => (
              <div key={index} className={styles.tag}>
                #{tag}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default MediumBlogItem
