import React from "react"
import Layout from "../components/layout"

import Headline from "../components/headline/headline.component"
import BlogPreview from "../components/blog-preview/blog-preview.component"

import SEO from "../components/seo"
import MediumBlogPreview from "../components/blog-preview/blog-preview-medium.component"
const BlogsPage = () => {
  return (
    <Layout>
      <SEO
        title="My Blogs"
        description="These are some applications that I have built"
      />
      <Headline
        mainText="Welcome To My Blog"
        subText="Where I share my thoughts about software development, tech and random things"
      />
      <div className="container">
        {/* <BlogPreview limit={5} /> */}
        <MediumBlogPreview />
      </div>
    </Layout>
  )
}

export default BlogsPage
